import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '智慧教学实训云'
    },
    redirect: '/index',
    component: () => import('../views/Home.vue'),
    children: [{
      path: '/index',
      name: 'index',
      meta: {
        title: '智慧教学实训云'
      },
      component: () => import('../views/index.vue'),
    },{
      path: '/job',
      name: 'job',
      meta: {
        title: '业务体系'
      },
      component: () => import('../views/job/job.vue'),
    },{
      path: '/integration',
      name: 'integration',
      meta: {
        title: '托幼一体'
      },
      component: () => import('../views/job/integration.vue'),
    },{
      path: '/fuse',
      name: 'fuse',
      meta: {
        title: '产教融合'
      },
      component: () => import('../views/job/fuse.vue'),
    },{
      path: '/anissue',
      name: 'anissue',
      meta: {
        title: '一问培训'
      },
      component: () => import('../views/job/anissue.vue'),
    },{
      path: '/science',
      name: 'science',
      meta: {
        title: '科技赋能'
      },
      component: () => import('../views/job/science.vue'),
    },{
      path: '/case',
      name: 'case',
      meta: {
        title: '典型案例'
      },
      component: () => import('../views/case.vue'),
    },{
      path: '/dynamic',
      name: 'dynamic',
      meta: {
        title: '集团动态'
      },
      component: () => import('../views/dynamic.vue'),
    },
    {
      path: '/dynamicinfo',
      name: 'dynamicinfo',
      meta: {
        title: '集团动态详情'
      },
      component: () => import('../views/dynamicinfo.vue'),
    },
    
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})
export default router
