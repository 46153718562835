
import email from "./email.js"
import dynamic from "./dynamic.js"



//进行统一导出
export default {
   email,
   dynamic

}
