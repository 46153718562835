/*
 * axios封装
 * 请求拦截、相应拦截、错误统一处理
 */

// QS 序列化传递的参数
// import QS from 'qs';
import axios from 'axios';
import router from '../router'
import { Message } from 'element-ui'
  axios.defaults.baseURL = 'https://www.bbbdedu.com:8088'

// 请求超时时间
axios.defaults.timeout = 20000;
axios.defaults.headers['Content-Type'] = 'application/json';

// 请求拦截器
axios.interceptors.request.use(
  config => {
     const token = sessionStorage.getItem('token');
     token && (config.headers.token = token);
    return config;
  },
  error => {
    return Promise.error(error);
  })

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      if (response.data.code == 900) {
        sessionStorage.clear()
        router.replace({
          name: 'login',
          query: { redirect: router.currentRoute.fullPath }
        });
        return Message.error(response.data.desc)
      }
      return Promise.resolve(response);

    } else {
      return Promise.reject(response);
    }
  },   
  error => {
    if (error.response.status) {
      return Promise.reject(error.response);
    }
  }
);

export default axios
