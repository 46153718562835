import axios from './http.js'
const dynamic = {
    getGroupDynamics(data) {
        return axios.get('/groupDynamics/getGroupDynamics', {params:data}  )//按类型获取集团动态
    },
    getGroupDynamicsDetail(data) {
        return axios.get('/groupDynamics/getGroupDynamicsDetail',  {params:data} )//按ID获取集团动态详情
    },
    
}
export default dynamic
